/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Layout } from 'antd';
import { withRouter, Route } from 'react-router';
import { SideMenu } from './side-menu';
import styles from './dashboard.styles';
import { DashboardRoutes, SideRoutes } from './dashboard.routes';
import { Header } from './header/header.component';

export const DashboardComponent = props => {
  const { history, logout } = props;

  const sideRoutes = [...SideRoutes, { title: 'Sign out', onClick: logout }];
  const changeRoute = route => {
    history.push(route.path);
  };
  const findRoute = () => {
    const { location } = history;
    let route;
    for (let key in DashboardRoutes) {
      let r = DashboardRoutes[key];
      if (r && r.path === location.pathname) {
        route = r;
        break;
      }
    }
    return route;
  };

  const currentRoute = findRoute();

  return (
    <Layout>
      <Header />
      <Layout>
        <SideMenu routes={sideRoutes} changeRoute={changeRoute} currentRoute={currentRoute} />
        <Layout>
          <Layout.Content id={'ContentContainer'} css={css(styles.contentContainer)}>
            {Object.keys(DashboardRoutes).map((key, i) => (
              <Route key={i} {...DashboardRoutes[key]} />
            ))}
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export const Dashboard = withRouter(DashboardComponent);
