import { get } from 'lodash';
import { convertWhere } from '../../utils';
import { instance } from './instance';

export const getSafes = async where => {
  const resp = await instance.post('/safe/retrieve', {
    ...convertWhere(where),
  });
  return get(resp, 'data.data', null);
};

export const getSafe = async safeId => {
  const response = await instance.get(`/safe/${safeId}`);
  return get(response, 'data.data', null);
};

export const getSafeBySerialNumber = async serialNumber => {
  const response = await instance.get(`/safe/serialNumber/${serialNumber}`);
  return get(response, 'data.data', null);
};

export const updateSafe = async safe => {
  const response = await instance.put('/safe', {
    ...safe,
  });
  const error = get(response, 'data.data.error');
  if (error) {
    throw new Error(error);
  }
  return get(response, 'data.data', null);
};

export const deleteAllSafeItems = async safe => {
  const response = await instance.post('/safe/deletesafe', {
    safe,
  });
  return get(response, 'data.data', null);
};
