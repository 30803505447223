/** @jsx jsx */
import { useState } from 'react';
import { jsx, css } from '@emotion/core';
import Content from './forgot-password-submit.content';
import { withRouter } from 'react-router-dom';
import { Form, Input, Alert } from 'antd';
import styles from '../authentication.styles';
import SharedStyles from '../../_shared/styles';
import { getContent, testPassword, PWReqsMessage, buildErrorMsgFromForm } from '../../utils';
import { Button, Logo } from '../../_shared/components';

const FGPasswordComponent = props => {
  const { signIn, forgotPasswordSubmit, form, history } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const goToLogin = () => {
    history.push('/login');
  };
  const submit = async e => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    form.validateFieldsAndScroll(async (err, values) => {
      const { code, pw1 } = values;
      if (err) {
        const error = buildErrorMsgFromForm(err);
        setLoading(false);
        setError(error);
        return;
      }

      if (!testPassword(pw1)) {
        setLoading(false);
        setError(PWReqsMessage);
        return;
      }

      if (pw1 !== pw2) {
        setLoading(false);
        setError('Passwords entered must match');
        return;
      }

      try {
        const { email } = props.history.location.state;
        await forgotPasswordSubmit(email, code, pw1);
        await signIn(email, pw1);
        history.push('/dashboard');
      } catch (err) {
        setLoading(false);
        setError(err.message);
      }
    });
  };
  const { code, pw1, pw2 } = form.getFieldsValue(['code', 'pw1', 'pw2']);
  const btnDisabled = !(code && pw1 && pw1 === pw2);
  const content = getContent(Content);

  return (
    <div className="ForgotPasswordSubmit" css={css(styles.container)}>
      <div css={css(SharedStyles.logoWrap)}>
        <Logo />
      </div>

      <p css={css(styles.title)}>{content.title}</p>
      <p css={css(styles.subTitle)}>{content.subTitle}</p>

      {!!error && (
        <Alert
          message={error}
          css={css(styles.alert)}
          type={'error'}
          closable
          onClose={() => setError(null)}
        />
      )}

      <div css={css(styles.inputContainer)}>
        <Form layout="vertical" onSubmit={submit}>
          <Form.Item label={content.codeLabel}>
            {form.getFieldDecorator('code', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  message: 'Code is required',
                },
                {
                  max: 10,
                  message: 'Code is limited to 10 characters',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={content.passwordLabel}>
            {form.getFieldDecorator('pw1', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  message: 'New Password is required',
                },
                {
                  max: 50,
                  message: 'Password is limited to 50 characters',
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label={content.confirmPasswordLabel}>
            {form.getFieldDecorator('pw2', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  message: 'Confirm Password is required',
                },
                {
                  max: 50,
                  message: 'Password is limited to 50 characters',
                },
                {
                  validator: () => pw1 === pw2,
                  message: 'Passwords entered must match',
                },
              ],
            })(<Input.Password />)}
          </Form.Item>

          <Form.Item>
            <div css={css(styles.buttonContainer + 'margin-top: 20px;')}>
              <Button
                loading={loading ? 1 : 0}
                title={content.button}
                onClick={submit}
                disabled={btnDisabled}
                width={300}
              />

              <div
                className="Link"
                css={css`
                  margin-top: 40px;
                  font-size: 14px;
                `}
                onClick={goToLogin}
              >
                {content.link}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export const ForgotPasswordSubmit = Form.create()(withRouter(FGPasswordComponent));
