export default () => {
  return {
    title: 'Change Your Password',
    subTitle: 'Enter the information below to change your password.',
    oldPasswordLabel: 'Old Password',
    passwordLabel: 'New Password',
    confirmPasswordLabel: 'Confirm Password',
    button: 'Change Password',
    link: 'Return to My Account',
  };
};
