/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Icon } from 'antd';
import { isLoggedIn, getUrlParams } from '../utils';
import styles from './gateway.styles';

export class GateWayComponent extends Component {
  state = { thinking: true, redirect: null };
  initialParams = {};
  componentDidMount = () => {
    const { appState } = this.props;
    const isLogged = isLoggedIn(appState);
    const initialUrl = window.location;
    const params = getUrlParams(initialUrl.href);
    this.initialParams = params;

    if (!isLogged) {
      this.redirect('/login');
    } else {
      this.redirect('/dashboard');
    }
  };
  redirect = loc => {
    this.setState({
      redirect: loc,
      thinking: false,
    });
  };
  render() {
    const { redirect, thinking } = this.state;

    if (redirect) {
      return <Redirect to={redirect || '/'} />;
    }

    return thinking ? (
      <div css={css(styles.thinkWrap)}>
        <Icon type="loading" css={css(styles.icon)} />
      </div>
    ) : (
      <span />
    );
  }
}

export default connect(appState => ({ appState }))(GateWayComponent);
