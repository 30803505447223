import colors from '../../_shared/colors';
export const styles = {
  container: `
    display: flex;
    flex-direction: row;
    height: 70px;
    background-color: ${colors.lightBackground};
    line-height: 48px;
    border-bottom: 2px solid ${colors.highlight};
    align-items: center;
    padding: 0 20px 0 20px;
    justify-content: space-between;
  `,
  search: `
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55%;

    .ant-input-wrapper.ant-input-group {
      max-width: 500px;
    }
  `,
  icon: `
    font-size: 24px;
    color: ${colors.white};
  `,
  userContainer: `
    display: flex;
    align-items: center;
    flex-wrap: no-wrap;
    cursor: pointer;
  `,
  userName: `
    font-size: 18px;
    margin-left: 15px;
    font-weight: 600;
    color: ${colors.highlight}
  `,
  iconContainer: `
    display:flex;
    background-color: ${colors.lightGray};
    height: 40px;
    width: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
  `,
};
