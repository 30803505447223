import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Footer } from '../footer';
import { ForgotPassword, Login, ForgotPasswordSubmit, SetUpAccount } from '../authentication';
import { Dashboard } from '../dashboard';
import GateWay from './gateway.component';

export default class Navigation extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <GateWay />
          <Route path="/login" component={Login} />
          <Route path="/forgotPassword" component={ForgotPassword} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/forgotPasswordSubmit" component={ForgotPasswordSubmit} />
          <Route path="/setUpAccount" component={SetUpAccount} />
          <Footer />
        </div>
      </Router>
    );
  }
}
