import { connect } from 'react-redux';
import { ManageUsers as ManageUsersComponent } from './manage-users.component';
import { retrieveData } from '../dashboard.actions';

const mapStateToProps = state => {
  const { user } = state.auth;
  const { dash } = state;
  return {
    user,
    dash,
  };
};

const mapDispatchToProps = {
  retrieveData,
};

export const ManageUsers = connect(mapStateToProps, mapDispatchToProps)(ManageUsersComponent);
