import { connect } from 'react-redux';
import { ManageUser as ManageUserComponent } from './manage-user.component';
import { retrieveData } from '../dashboard.actions';

const mapStateToProps = state => {
  const { dash } = state;
  return {
    pairingHistories: dash.pairingHistories,
    safes: dash.safes,
    users: dash.users,
  };
};

const mapDispatchToProps = {
  retrieveData,
};

export const ManageUser = connect(mapStateToProps, mapDispatchToProps)(ManageUserComponent);
