/** @jsx jsx */
import { useState } from 'react';
import { jsx, css } from '@emotion/core';
import Content from './change-password.content';
import { withRouter } from 'react-router-dom';
import { Form, Input, Alert } from 'antd';
import styles from './change-password.styles';
import { getContent, testPassword, PWReqsMessage, buildErrorMsgFromForm } from '../../utils';
import { Button } from '../../_shared/components';
import { changePassword } from '../../_shared/services/authentication.service';

const ChangePasswordComponent = props => {
  const { form, history } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const toDisplay = [
    {
      title: 'Current Password',
      var: 'currentPassword',
      validateTrigger: 'onBlur',
      options: {
        rules: [
          {
            required: true,
            message: 'Current Password is required',
          },
          {
            max: 50,
            message: 'Current Password is limited to 50 characters. ',
          },
        ],
      },
    },
    {
      title: 'New Password',
      var: 'pw1',
      options: {
        validateTrigger: 'onBlur',
        validateFirst: true,
        rules: [
          {
            required: true,
            message: 'New Password is required',
          },
          {
            max: 50,
            message: 'Password is limited to 50 characters. ',
          },
        ],
      },
    },
    {
      title: 'Confirm New Password',
      var: 'pw2',
      options: {
        validateTrigger: 'onBlur',
        validateFirst: true,
        rules: [
          {
            required: true,
            message: 'Confirm Password is required',
          },
          {
            max: 50,
            message: 'Password is limited to 50 characters. ',
          },
          {
            validator: (rule, value) => {
              const { pw1 } = form.getFieldsValue(['pw1']);
              return value === pw1;
            },
            message: 'Passwords entered must match',
          },
        ],
      },
    },
  ];

  const goToMyAccount = () => {
    history.push('/dashboard/myAccount');
  };

  const submit = async e => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    form.validateFieldsAndScroll(async (err, values) => {
      const { currentPassword, pw1, pw2 } = values;
      if (err) {
        const error = buildErrorMsgFromForm(err);
        setLoading(false);
        setError(error);
        return;
      }

      try {
        if (!testPassword(pw1)) {
          setLoading(false);
          setError(PWReqsMessage);
          return;
        }
        if (pw1 !== pw2) {
          setLoading(false);
          setError('Passwords entered must match');
          return;
        }
        await changePassword(currentPassword, pw1);
        setLoading(false);
        setSuccess('Password updated successfully');
      } catch (err) {
        const msg = err.message.toLowerCase();
        setLoading(false);
        setError(
          msg.indexOf('incorrect username') > -1
            ? 'Current Password entered is incorrect'
            : 'Change Password Failed: ' + err.msg
        );
      }
    });
  };
  const { currentPassword, pw1, pw2 } = form.getFieldsValue(['currentPassword', 'pw1', 'pw2']);
  const btnDisabled = !(currentPassword && pw1 && pw1 === pw2);
  const content = getContent(Content);

  return (
    <div className="ForgotPasswordSubmit" css={css(styles.container)}>
      <p css={css(styles.title)}>{content.title}</p>
      <p css={css(styles.subTitle)}>{content.subTitle}</p>

      {error && (
        <Alert
          css={css(styles.alert)}
          message={error}
          type={'error'}
          closable
          onClose={() => setError(null)}
        />
      )}
      {success && (
        <Alert
          css={css(styles.alert)}
          type="success"
          message={success}
          closable
          onClose={() => setSuccess(null)}
        />
      )}

      <div css={css(styles.inputContainer)}>
        <Form layout="vertical" onSubmit={submit}>
          {toDisplay.map((item, i) => {
            return (
              <Form.Item key={i} label={item.title}>
                {form.getFieldDecorator(item.var, item.options)(<Input.Password />)}
              </Form.Item>
            );
          })}
        </Form>
        <div css={css(styles.buttonContainer + 'margin-top: 20px;')}>
          <Button
            loading={loading ? 1 : 0}
            title={content.button}
            onClick={submit}
            disabled={btnDisabled}
          />

          <div
            className="Link"
            css={css`
              margin-top: 40px;
              font-size: 14px;
            `}
            onClick={goToMyAccount}
          >
            {content.link}
          </div>
        </div>
      </div>
    </div>
  );
};

export const ChangePassword = Form.create()(withRouter(ChangePasswordComponent));
