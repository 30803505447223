export const AuthActions = {
  LOG_USER_OUT: 'LOG_USER_OUT',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_TOKEN: 'SET_TOKEN',
};

const initialState = {
  user: {},
};

export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case AuthActions.LOG_USER_OUT:
      return {
        ...state,
        user: {},
      };
    case AuthActions.SET_TOKEN:
      return {
        ...{},
        ...state,
        token: action.token,
      };
    case AuthActions.SET_CURRENT_USER:
      return {
        ...state,
        user: action.payload,
      };
  }
};
