/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import colors from '../colors';

export default class FormTitle extends Component {
  render() {
    const { title, children, size } = this.props;
    return (
      <p
        {...this.props}
        css={css(
          `
          color: ${colors.highlight};
          font-size: ${size || '12px'};
          font-weight: bold;
        `,
          this.props.css
        )}
      >
        {title || children}
      </p>
    );
  }
}
