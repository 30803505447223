import { MyAccount } from './my-account';
import { ManageUsers } from './manage-users';
import { ManageUser } from './manage-user';
import { ManageSerials } from './manage-serials';
import { ChangePassword } from './change-password';
import { ExportData } from './export-data';

export const SideRoutes = [
  {
    title: 'Manage Users',
    path: '/dashboard',
  },
  {
    title: 'Serials',
    path: '/dashboard/manageSerials',
  },
  {
    title: 'Export Data',
    path: '/dashboard/exportData',
  },
];

export const DashboardRoutes = [
  {
    title: 'My Account',
    path: '/dashboard/myAccount',
    component: MyAccount,
  },
  {
    title: 'Manage Users',
    path: '/dashboard',
    component: ManageUsers,
    exact: true,
    highlighted: '/dashboard',
  },
  {
    title: 'Manage User',
    path: '/dashboard/manageUser',
    component: ManageUser,
    highlighted: '/dashboard',
  },
  {
    title: 'Serials',
    path: '/dashboard/manageSerials',
    component: ManageSerials,
    highlighted: '/dashboard/manageSerials',
  },
  {
    title: 'Change Password',
    path: '/dashboard/changePassword',
    component: ChangePassword,
    highlighted: '/dashboard/myAccount',
  },
  {
    title: 'Export Data',
    path: '/dashboard/exportData',
    component: ExportData,
    highlighted: '/dashboard/exportData',
  },
];
