import colors from '../_shared/colors';

export default {
  container: `
    display: flex;
    flex: 1;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,
  title: `
    color: ${colors.highlight};
    font-size: 16px;
    font-weight: bold;
    margin: 10px 0;
    text-align: center;
  `,
  brandDiv: `
    margin: 60px 0;
  `,
  brandImage: `
    width: 100%;
    height: 100%;
  `,
};
