import { getUsers, getSafes, getDeletedUsers, getPairingHistories } from '../_shared/services';
import { DASH_ACTIONS } from './dashboard.reducer';
import { chain, get } from 'lodash';

const toggleLoading = loading => dispatch => {
  dispatch({
    type: DASH_ACTIONS.TOGGLE_DASH_LOADING,
    payload: loading,
  });
};

export const retrieveData = () => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    const [users, deletedUsers, safes] = await Promise.all([
      getUsers(),
      getDeletedUsers(),
      getSafes(),
    ]);
    const safeMap = chain(safes).keyBy('sk').value();
    const myCubeUsers = users
      .filter(user => !user.isSuperAdmin)
      .map(user => {
        const _safes = (user.safes || []).map(s => get(safeMap, s));
        return {
          ...user,
          _safes,
        };
      });
    dispatch({
      type: DASH_ACTIONS.RETRIEVE_DATA,
      payload: {
        users: myCubeUsers,
        deletedUsers,
        safes: safeMap,
      },
    });
    dispatch(toggleLoading(false));
    return {
      users: myCubeUsers,
      deletedUsers,
      safes,
    };
  } catch (error) {
    dispatch({
      type: DASH_ACTIONS.RETRIEVE_DATA_ERROR,
      error: error.message,
    });
    dispatch(toggleLoading(false));
  }
};

export const retrieveUsers = () => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    const users = await getUsers();
    const myCubeUsers = users.filter(user => !user.isSuperAdmin);
    dispatch({
      type: DASH_ACTIONS.RETRIEVE_USERS,
      payload: {
        users: myCubeUsers,
      },
    });
    dispatch(toggleLoading(false));
    return {
      users: myCubeUsers,
    };
  } catch (error) {
    dispatch({
      type: DASH_ACTIONS.RETRIEVE_USERS_ERROR,
      error: error.message,
    });
    dispatch(toggleLoading(false));
  }
};

export const retrieveSafes = () => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    const safes = await getSafes();
    dispatch({
      type: DASH_ACTIONS.RETRIEVE_SAFES,
      payload: {
        safes,
      },
    });
    dispatch(toggleLoading(false));
    return {
      safes,
    };
  } catch (error) {
    dispatch({
      type: DASH_ACTIONS.RETRIEVE_SAFES_ERROR,
      error: error.message,
    });
    dispatch(toggleLoading(false));
  }
};

export const retrievePairingHistories = () => async dispatch => {
  dispatch(toggleLoading(true));
  try {
    const pairingHistories = await getPairingHistories();
    dispatch({
      type: DASH_ACTIONS.RETRIEVE_PAIRING_HISTORIES,
      payload: {
        pairingHistories,
      },
    });
    dispatch(toggleLoading(false));
    return {
      pairingHistories,
    };
  } catch (error) {
    dispatch({
      type: DASH_ACTIONS.RETRIEVE_PAIRING_HISTORIES_ERROR,
      error: error.message,
    });
    dispatch(toggleLoading(false));
  }
};
