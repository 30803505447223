/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Card, List } from 'antd';
import { Button, Icon } from '../../_shared/components';
import colors from '../../_shared/colors';
const MyAccountComponent = props => {
  const {
    user,
    user: { firstName, lastName, email, phoneNumber },
    history,
  } = props;

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (loading && user) {
      setLoading(false);
    }
  }, [user, loading]);

  const onChangePassword = () => history.push('/dashboard/changePassword');

  if (loading) {
    return <Icon name={'spinner fa-spin'} color={colors.lightGray} size={18} />;
  }

  return (
    <Card
      extra={
        <div>
          <Button onClick={onChangePassword} title={'Change Password'} />
        </div>
      }
    >
      <List
        itemLayout="horizontal"
        dataSource={[
          { key: 'First Name', value: firstName },
          { key: 'Last Name', value: lastName },
          { key: 'Email', value: email },
          { key: 'Phone Number', value: phoneNumber || '' },
        ]}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={
                <p
                  css={css`
                    margin-top: 10px;
                    font-size: 16px;
                  `}
                >
                  {item.key}: {item.value}
                </p>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export const MyAccount = withRouter(MyAccountComponent);
