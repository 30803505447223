import { connect } from 'react-redux';
import { ManageSerials as ManageSerialsComponent } from './manage-serials.component';

const mapStateToProps = state => {
  const { user } = state.auth;
  return {
    user,
  };
};

export const ManageSerials = connect(mapStateToProps)(ManageSerialsComponent);
