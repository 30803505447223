export default () => {
  return {
    title: 'Set Up Your Account',
    subTitle:
      'Please enter your name, phone number (optional), and a new password to finish setting up your account.',
    passwordLabel: 'New Password',
    firstNameLabel: 'First Name',
    lastNameLabel: 'Last Name',
    confirmPasswordLabel: 'Confirm Password',
    button: 'Submit',
    link: 'Return to Sign In',
    phoneLabel: 'Phone Number',
  };
};
