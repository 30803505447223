/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Component } from 'react';
import { TableList, TableListHeader } from '../../_shared/table-list';
import { Input, Checkbox, Alert, Icon } from 'antd';
import SharedStyles from '../../_shared/styles';
import { withRouter } from 'react-router';
import colors from '../../_shared/colors';
import moment from 'moment';
import { sortByLastThenFirstName, sortByKey, sortByBoolean, sortByCreatedAt } from '../../utils';
import { get } from 'lodash';

const Columns = () => {
  const cols = [
    {
      title: 'Name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => sortByLastThenFirstName(a, b),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => sortByKey(a, b, 'email'),
    },
    {
      title: 'Safes',
      dataIndex: '_safes',
      render: text => {
        return (text || [])
          .filter(s => s)
          .map(s => s.serial)
          .join(', ');
      },
    },
    {
      title: 'Active',
      dataIndex: 'status',
      sorter: (a, b) => sortByBoolean(a, b, 'status'),
      render: (_text, record) => {
        const user = record;
        const isActive = user.status > 0;
        return (
          <Icon
            type={isActive ? 'check-circle' : 'close-circle'}
            css={css(`color: ${isActive ? colors.success : colors.error};`)}
          />
        );
      },
    },
    {
      title: 'Deleted',
      dataIndex: 'isDeleted',
      // eslint-disable-next-line react/display-name
      render: (_text, record) => {
        const user = record;
        const isDeleted = !!user.isDeleted;
        return <span>{isDeleted ? 'True' : 'False'}</span>;
      },
      sorter: (a, b) => sortByBoolean(a, b, 'isDeleted'),
    },
    {
      title: 'Created',
      dataIndex: 'createdAt',
      sorter: (a, b) => sortByCreatedAt(a, b),
      // eslint-disable-next-line react/display-name
      render: (_text, record) => {
        const user = record;
        const dateCreated = user.createdAt;
        return <span>{moment(dateCreated).format('MM/DD/YYYY')}</span>;
      },
    },
  ].filter(c => c);
  return cols;
};

class ManageUsersComponent extends Component {
  state = {
    columns: [...Columns(this)],
    success: null,
    data: [],
    searchValue: null,
    showDeleted: false,
    show: true,
  };
  componentDidMount = () => {
    const { state } = this.props.history.location;
    if (state && state.toast) {
      this.setState({ success: state.toast });
    }
    this.retrieveData(true);
  };

  manageUser = user => {
    this.props.history.push('/dashboard/manageUser', {
      user,
    });
  };

  selectUser = record => {
    this.manageUser(record._user);
  };
  selectedRowsChange = keys => {
    this.setState({
      selectedRows: keys,
    });
  };
  fireSearch = value => {
    this.setState({ searchValue: value }, () => this.retrieveData(false));
  };
  retrieveData = async (shouldFetch = true) => {
    const { showDeleted, searchValue } = this.state;
    const { dash } = this.props;

    try {
      const { users, deletedUsers } = shouldFetch
        ? await this.props.retrieveData()
        : { users: dash.users, deletedUsers: dash.deletedUsers };

      const sv = (searchValue || '').toUpperCase();

      const data = [...users, ...deletedUsers]
        .map((user, i) => {
          return {
            ...user,
            _user: { ...user },
            key: i,
            isDeleted: user.isDeleted || user.pk === 'deletedUser',
            name: `${user.firstName} ${user.lastName}`,
          };
        })
        .filter(user => {
          let isVisible = true;

          if (!showDeleted && isVisible) {
            isVisible = !user.isDeleted;
          }

          if (sv && isVisible) {
            isVisible =
              user.name.toUpperCase().indexOf(sv) > -1 ||
              user.email.toUpperCase().indexOf(sv) > -1 ||
              get(user, '_safes', [])
                .filter(f => f)
                .map(s => (s.serial || '').toUpperCase())
                .join('')
                .indexOf(sv) > -1;
          }

          return isVisible;
        });

      // tslint:disable-next-line:no-any
      const columns = [];
      Columns().map(col => {
        columns.push(col);
      });

      this.setState({
        data,
        columns,
        show: true,
      });
    } catch (err) {
      console.warn(err);
    }
  };

  getSelectedUsers = () => {
    const { selectedRows, allUsers } = this.state;
    return selectedRows.map(k => allUsers[k]);
  };

  render() {
    const { columns, data, showDeleted, success } = this.state;
    const { loading, error } = this.props.dash;

    return (
      <div>
        {error && <Alert css={css(SharedStyles.formAlert)} type="error" message={error} closable />}

        {success && (
          <Alert
            css={css(SharedStyles.formAlert)}
            type="success"
            message={success}
            closable
            onClose={() => this.setState({ success: null })}
          />
        )}

        <TableListHeader>
          <div
            css={css`
              width: 50%;
              align-items: center;
              justify-content: center;
            `}
          >
            <Input.Search
              css={css`
                width: 70%;
                margin-right: 10px;
              `}
              placeholder="Search..."
              enterButton="Search"
              size="default"
              onSearch={this.fireSearch}
            />

            <Checkbox
              checked={showDeleted}
              css={css`
                margin: 10px 0 0 10px;
              `}
              onChange={() =>
                this.setState({ showDeleted: !showDeleted }, () => this.retrieveData(false))
              }
            >
              Show Deleted
            </Checkbox>
          </div>
        </TableListHeader>
        <TableList
          canSelectRows={false}
          loading={loading}
          columns={columns}
          data={data}
          rowClick={this.selectUser}
        />
      </div>
    );
  }
}

export const ManageUsers = withRouter(ManageUsersComponent);
