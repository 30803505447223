import axios from 'axios';
import { getUrl } from '../config';
import { get, set } from 'lodash';
import { store } from '../../app.component';

const createInstance = () => {
  const baseURL = getUrl();
  return axios.create({ baseURL });
};

const getStore = () => {
  return store || { getState: () => {} };
};

const getCurrentToken = () => {
  const store = getStore();
  const sState = store.getState();
  return get(sState, 'auth.token', '');
};

export const instance = createInstance();

instance.interceptors.request.use(
  config => {
    if (!get(config, 'headers.Authorization')) {
      set(config, 'headers.Authorization', `Bearer ${getCurrentToken()}`);
    }

    return config;
  },
  error => {
    throw Promise.reject(error);
  }
);

let loggingOut = false;
instance.interceptors.response.use(
  value => {
    return value;
  },
  async err => {
    const status = get(err, 'response.status');
    const error = new Error(get(err, 'response.data.error', err.message));

    if (status == 401 && !loggingOut) {
      loggingOut = true;
      await localStorage.clear();
      alert('Your token is no longer valid. Please login again.');
      window.location.reload();
    }

    throw error;
  }
);
