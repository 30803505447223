export class _User {
  constructor(props) {
    for (let key in props) {
      this[key] = props[key];
    }
  }
  email = ''; //userId
  createdAt = new Date().toISOString();
  createdBy = '';
  activatedAt = undefined;
  updatedAt = new Date().toISOString();
  updatedBy = undefined;
  isSuperAdmin = true;
  isDeleted = false;
  status = 1;
  firstName = '';
  lastName = '';
  phoneNumber = '';
}
