/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Layout, Icon } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { styles } from './header.styles';
import { Logo } from '../../_shared/components';

export const HeaderComponent = props => {
  const { history, user } = props;
  const nameClick = () => history.push('/dashboard/myAccount');
  return (
    <Layout.Header css={css(styles.container)}>
      <Logo small={true} />
      <div onClick={nameClick} css={css(styles.userContainer)}>
        <div css={css(styles.iconContainer)}>
          <Icon type="user" css={css(styles.icon)} />
        </div>

        <div css={css(styles.userName)}>{user.firstName + ' ' + user.lastName}</div>
      </div>
    </Layout.Header>
  );
};

const mapStateToProps = state => {
  const { auth } = state;
  return {
    user: auth.user,
  };
};

export const Header = connect(mapStateToProps)(withRouter(HeaderComponent));
