import { get } from 'lodash';
import moment from 'moment';

export const getContent = content => {
  return content();
};

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const testEmail = email => {
  return emailRegex.test(email);
};

export const isLoggedIn = appState => {
  const { auth } = appState;
  return get(auth, 'user.email');
};

export const testPassword = password => {
  // eslint-disable-next-line no-useless-escape
  var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&/,><\’:;|_~`])\S{6,99}$/;
  return re.test(password);
};

export const PWReqsMessage =
  // eslint-disable-next-line quotes
  `Passwords must be at least 8 characters, and can contain letters, numbers and any of the following special characters:  ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < ' : ; | _ ~ \``;

export const getUrlParams = url => {
  let match;
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = function (s) {
    return decodeURIComponent(s);
  };
  const searchFor = '?';
  const index = url.indexOf(searchFor) + searchFor.length;
  const str = url.substring(index, url.length);
  const query = str;

  let urlParams = {};

  while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2]);

  return urlParams;
};

export const convertWhere = (__where, opts = {}) => {
  const where = {
    FilterExpression: '',
    ExpressionAttributeValues: {},
    Limit: undefined,
    ...opts,
  };

  const parse = {};

  const parseObj = (_where, isOr = false) => {
    for (let k in _where) {
      if (k.startsWith('_') && k !== '_data_') {
        const _k = k.substring(1, k.length);
        parse[_k] = _where[k];
      } else {
        const obj = _where[k];
        const isAnd = where.FilterExpression.length > 0 ? (isOr ? ' or ' : ' and ') : '';

        if (obj.eq) {
          where.FilterExpression += `${isAnd}${k} = :${k}`;
          where.ExpressionAttributeValues[`:${k}`] = obj.eq;
        } else if (obj.contains) {
          where.FilterExpression += `${isAnd}contains(${k}, :${k})`;
          where.ExpressionAttributeValues[`:${k}`] = obj.contains;
        } else if (obj.or) {
          let ors = `${isAnd ? ' and ' : ''}`;
          obj.or.map((a, i) => {
            const isFirst = i === 0;
            const isLast = obj.or && i === obj.or.length - 1;
            const varName = `:${k}${i}`;
            const or = `${isFirst ? '(' : ''}${k} = ${varName}${isLast ? ')' : ' or '}`;
            ors += or;
            where.ExpressionAttributeValues[varName] = a;
          });
          where.FilterExpression += ors;
        }
      }
    }
  };

  if (Array.isArray(__where)) {
    __where.map(o => parseObj(o, true));
    where.FilterExpression = '(' + where.FilterExpression + ')';
  } else {
    parseObj(__where);
  }

  return __where
    ? {
        where,
        parse,
      }
    : {};
};

export const sortByCreatedAt = (a, b) => {
  if (!a.createdAt) return 1;
  if (!b.createdAt) return -1;
  if (moment(a.createdAt).format('YYYYMMDD') === moment(b.createdAt).format('YYYYMMDD')) return 0;
  return moment(a.createdAt).format('YYYYMMDD') > moment(b.createdAt).format('YYYYMMDD') ? 1 : -1;
};

export const sortByLastThenFirstName = (a, b) => {
  if (a.lastName?.toLowerCase() < b.lastName?.toLowerCase()) return -1;
  if (a.lastName?.toLowerCase() > b.lastName?.toLowerCase()) return 1;
  if (a.firstName?.toLowerCase() < b.firstName?.toLowerCase()) return -1;
  if (a.firstName?.toLowerCase() > b.firstName?.toLowerCase()) return 1;
  return 0;
};

export const sortByKey = (a, b, key) => {
  if (!a[key]) return 1;
  if (!b[key]) return -1;
  if (a[key].toLowerCase() === b[key].toLowerCase()) return 0;
  return a[key].toLowerCase() < b[key].toLowerCase() ? -1 : 1;
};

export const sortByBoolean = (a, b, key) => {
  return a[key] === b[key] ? 0 : a[key] > b[key] ? 1 : -1;
};

export const removeEmpty = obj => {
  for (let key in obj) {
    let o = obj[key];
    if (o === null) {
      obj[key] = 'null';
    } else if (o === undefined || o.length === 0) {
      delete obj[key];
    } else if (Array.isArray(o)) {
      obj[key] = o.map(oo => {
        if (oo === null) {
          return 'null';
        } else {
          return oo;
        }
      });
    } else if (typeof o === 'object') {
      o = removeEmpty(o);
    }
  }
  return obj;
};

export const buildErrorMsgFromForm = objErrors => {
  let message = '';
  for (let key in objErrors) {
    let all = get(objErrors, `${key}.errors`, []);
    all.map(e => {
      message += e.message + '\n';
    });
  }
  return message;
};

export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  // if (/windows phone/i.test(userAgent)) {
  //   return 'Windows Phone';
  // }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};

export const pullOutDataForSafeUser = (u, extras = {}) => {
  extras = {
    isAdmin: false,
    ...extras,
  };
  return {
    userId: get(u, 'userId', get(u, 'email', '')),
    firstName: get(u, 'firstName', ''),
    lastName: get(u, 'lastName', ''),
    sDate: get(extras, 'datePaired', new Date().toISOString()),
    ...extras,
  };
};

export const exportToCsv = (filename, rows) => {
  var processRow = function (row) {
    var finalVal = '';
    for (var j = 0; j < row.length; j++) {
      var innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      } else if (row[j] instanceof Object) {
        innerValue = get(row[j], 'name', '');
      }
      var result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = '"' + result + '"';
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    return finalVal + '\n';
  };

  var csvFile = '';
  for (var i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      var url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
