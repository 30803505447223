import { connect } from 'react-redux';
import { Login as LoginComponent } from './login';
import { ForgotPassword as ForgotPasswordComponent } from './forgot-password';
import { ForgotPasswordSubmit as FGPasswordSubmitComponent } from './forgot-password-submit';
import { SetUpAccount as SetUpAccountComponent } from './setupAccount';
import {
  signUp,
  verify,
  signIn,
  forgotPassword,
  forgotPasswordSubmit,
} from './authentication.actions';

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

const mapDispatchToProps = {
  signUp,
  verify,
  signIn,
  forgotPassword,
  forgotPasswordSubmit,
};

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
export const ForgotPassword = connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordComponent);
export const SetUpAccount = connect(mapStateToProps, mapDispatchToProps)(SetUpAccountComponent);
export const ForgotPasswordSubmit = connect(
  mapStateToProps,
  mapDispatchToProps
)(FGPasswordSubmitComponent);
