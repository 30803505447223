import Amplify from 'aws-amplify';
import config from '../_shared/config';

export default () => {
  const conf = config();

  Amplify.configure({
    Auth: {
      identityPoolId: conf.aws_identity_pool,
      region: conf.aws_region,
      userPoolId: conf.aws_user_pool,
      userPoolWebClientId: conf.aws_webclient_id,
    },
  });

  if (process.env.REACT_APP_ENV === 'dev') {
    require('./support');
  }
};
