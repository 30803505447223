export default () => {
  return {
    title: 'Forgot Your Password?',
    resetPassword: 'Reset Your Temporary Password',
    subTitle: 'Enter the email associated with your account and we will send you a reset code.',
    emailLabel: 'Email',
    button: 'Send Code',
    link: 'Return to Sign In',
  };
};
