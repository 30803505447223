export default {
  title: `
    margin-bottom: -5px;
    font-size: 16px;
  `,
  loading: `
    display: flex;
    margin-top: 100px;
    justify-content: center;
    width: 100%;
  `,
};
