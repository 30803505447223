import { connect } from 'react-redux';
import { ChangePassword as ChangePasswordComponent } from './change-password.component';

const mapStateToProps = state => {
  const { auth } = state;
  return {
    user: auth.user,
  };
};

export const ChangePassword = connect(mapStateToProps)(ChangePasswordComponent);
