/** @jsx jsx */
import { useState } from 'react';
import { jsx, css } from '@emotion/core';
import { Form, Input, Alert } from 'antd';
import { withRouter } from 'react-router-dom';
import SharedStyles from '../../_shared/styles';
import Content from './login.content';
import { getContent, buildErrorMsgFromForm } from '../../utils';
import { Button } from '../../_shared/components';
import { Logo } from '../../_shared/components';
import styles from '../authentication.styles';

const LoginComponent = props => {
  const { signIn, history, form } = props;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const goToForgotPassword = () => {
    const email = form.getFieldValue('email');
    history.push('/forgotPassword', { email });
  };

  const signInSubmit = async e => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        setError(error);
        setLoading(false);
        return;
      }

      const { email, password } = values;

      const response = await signIn(email, password);

      if (!response) {
        setLoading(false);
        setError('That username and password combination are not valid');
        return;
      }
      if (response.challengeName && response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        history.push('/setUpAccount', { email });
        return;
      }
      history.push('/dashboard');
    });
  };
  const { email, password } = form.getFieldsValue(['email', 'password']);
  const disabled = !email || !password;
  const content = getContent(Content);

  return (
    <div className="Login" css={css(styles.container)}>
      <div css={css(SharedStyles.logoWrap)}>
        <Logo />
      </div>

      <p css={css(styles.title)}>{content.signIn}</p>

      {!!error && (
        <Alert
          message={error}
          css={css(styles.alert)}
          type={'error'}
          closable
          onClose={() => setError(null)}
        />
      )}

      <div css={css(styles.inputContainer)}>
        <Form layout="vertical" onSubmit={signInSubmit}>
          <Form.Item label={content.emailLabel}>
            {form.getFieldDecorator('email', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  type: 'email',
                  message: 'Enter a valid email address',
                },
                {
                  required: true,
                  message: 'Email is required',
                },
                {
                  max: 50,
                  message: 'Email is limited to 50 characters',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={content.passwordLabel}>
            {form.getFieldDecorator('password', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  message: 'Password is required',
                },
                {
                  max: 50,
                  message: 'Password limited to 50 characters.',
                },
              ],
            })(<Input.Password />)}
          </Form.Item>

          <Form.Item>
            <div css={css(styles.buttonContainer, 'margin-top: 30px')}>
              <Button
                loading={loading ? 1 : 0}
                title={content.button}
                onClick={signInSubmit}
                disabled={disabled}
                width={300}
              />
              <Button
                outline
                title={content.forgotPasswordBtn}
                width={300}
                onClick={goToForgotPassword}
                css={css`
                  margin-top: 20px;
                `}
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export const Login = Form.create()(withRouter(LoginComponent));
