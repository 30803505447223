/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import colors from '../../_shared/colors';
import { get, size, keyBy } from 'lodash';
import { Card, List, Icon as AntdIcon, Switch, Alert, Modal, Select } from 'antd';
import { Icon, Button } from '../../_shared/components';
import moment from 'moment';
import { sortByKey } from '../../utils';
import { TableList, TableListHeader } from '../../_shared/table-list';
import SharedStyles from '../../_shared/styles';
import styles from './manage-user.styles';
import { updateUser, getUser } from '../../_shared/services';
import { updateSafe, deleteAllSafeItems } from '../../_shared/services/manage-safes.service';
import { deleteUser } from '../../_shared/services/authentication.service';

const validUsers = users => {
  return users.filter(u => !u.endDate);
};

const Columns = props => {
  const cols = [
    {
      title: 'Name',
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => sortByKey(a, b, 'name'),
    },
    {
      title: 'Serial',
      dataIndex: 'serial',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => sortByKey(a, b, 'serial'),
    },
    {
      title: 'DeviceId',
      dataIndex: 'deviceId',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => sortByKey(a, b, 'deviceId'),
    },
    {
      title: 'Date Paired',
      dataIndex: 'datePaired',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => sortByKey(a, b, 'datePaired'),
      render: text => {
        return moment(text).format('L');
      },
    },
    {
      title: 'Other Users',
      dataIndex: 'otherusers',
      render: (_text, record) => {
        const { user } = props;
        const users = validUsers(record.users || [])
          .filter(u => u.userId !== get(user, 'userId'))
          .map(u => `${get(u, 'firstName')} ${get(u, 'lastName')}`);
        return (
          <div>
            {users.map(u => (
              <div key={u}>{u}</div>
            ))}
          </div>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: '',
      render: (_text, record) => {
        return (
          <div>
            <Button
              style={{ marginBottom: 5 }}
              onClick={() => props.removeUser(record)}
              size="small"
              title="Remove User"
              type="primary"
            />
            <Button
              onClick={() => props.deleteSafe(record)}
              size="small"
              title="Delete Safe"
              type="danger"
            />
          </div>
        );
      },
    },
  ].filter(c => c);
  return cols;
};

const ManageUserComponent = props => {
  const { history, users, safes } = props;
  const [user, setUser] = useState(get(history, 'location.state.user', {}));
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [active, setActive] = useState();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    retrieveData(user);
  }, [users, safes, user]);

  const retrieveData = async _user => {
    const columns = [];
    Columns({
      user,
      deleteSafe,
      removeUser,
    }).map(col => {
      columns.push(col);
    });
    setUser(_user);
    setColumns(columns);
    setLoading(false);
    setActive(_user.status);
  };

  const getUpdatedUser = async () => {
    const u = await getUser(user.userId);
    const _safes = (u.safes || []).map(s => {
      return get(safes, s, {});
    });
    setUser({ ...u, _safes });
  };

  const toggleActive = async () => {
    setLoading(true);
    const _user = {
      ...user,
      _safes: undefined,
    };
    try {
      await updateUser({
        user: {
          ..._user,
        },
      });
      setUser({ ..._user, _safes: user._safes });
      setLoading(false);
      setSuccess('Success!');
      setTimeout(() => {
        setSuccess('');
      }, 3000);
    } catch (err) {
      setError(err.message);
      setTimeout(() => {
        setError('');
      }, 3000);
      setLoading(false);
    }
  };

  const onDeleteUser = () => {
    const update = async () => {
      setLoading(true);
      const _user = {
        ...user,
        _safes: undefined,
        status: 0,
      };
      try {
        await Promise.all([
          deleteUser({ user: _user }),
          user._safes.map(async s => {
            s.users = s.users.map(u => {
              if (!u.endDate && u.userId === user.userId) {
                u.endDate = moment().toISOString();
              }
              return u;
            });
            await updateSafe(s);
          }),
        ]);
        setLoading(false);
        setSuccess('Success!');
        props.history.goBack();
      } catch (err) {
        setError(err.message);
        setTimeout(() => {
          setError('');
        }, 3000);
        setLoading(false);
      }
    };
    Modal.confirm({
      title: 'Delete User?',
      content: <p>Are you sure you want to delete this user?</p>,
      canceText: 'Cancel',
      onCancel: () => 0,
      onOk: () => update(),
      okText: 'Delete',
    });
  };

  const deleteSafe = safe => {
    const update = async () => {
      setLoading(true);
      try {
        const umap = keyBy(users, 'userId');
        await Promise.all([
          (safe.users || []).map(async u => {
            const fulluser = get(umap, u.userId);

            fulluser.safes = get(fulluser, 'safes', []).filter(s => s !== safe.sk);

            await updateUser({ user: { ...fulluser, _safes: undefined } });
          }),
          await deleteAllSafeItems(safe),
        ]);

        await getUpdatedUser();

        setLoading(false);
        setSuccess('The safe has been successfully deleted');
        setTimeout(() => {
          setSuccess('');
        }, 3000);
      } catch (err) {
        setError(err.message);
        setTimeout(() => {
          setError('');
        }, 3000);
        setLoading(false);
      }
    };

    Modal.confirm({
      title: 'Delete Safe?',
      content: (
        <p>
          Are you sure you want to delete this safe?
          <br />
          <br />
          The safe record and all associated inventory items will be deleted, and users will no
          longer be able to access to this safe.
        </p>
      ),
      canceText: 'Cancel',
      onCancel: () => 0,
      onOk: () => update(),
      okText: 'Delete',
    });
  };

  const removeUser = safe => {
    let foundUser;
    let users = get(safe, 'users', []).map(u => {
      if (get(u, 'userId') === get(user, 'userId')) {
        foundUser = u;
        u.endDate = moment().toISOString();
      }
      return u;
    });

    const vusers = validUsers(users);
    const needToChooseNew = !!(get(foundUser, 'isAdmin') && size(vusers));

    let val;

    const update = async () => {
      setLoading(true);
      try {
        const _safe = { ...safe };

        const noMoreUsers = !size(vusers);

        if (needToChooseNew) {
          users = users.map(u => {
            if (!u.endDate && get(u, 'userId') === val) {
              u.isAdmin = true;
            }
            return u;
          });
        }

        _safe.users = users;

        const _user = { ...user, _safes: undefined };
        _user.safes = _user.safes.filter(s => s !== _safe.sk);

        await Promise.all([updateSafe(_safe), updateUser({ user: _user })]);

        if (noMoreUsers) {
          await deleteAllSafeItems(_safe);
        }

        await getUpdatedUser();

        setLoading(false);
        setSuccess('The user has been successfully removed from the safe');
        setTimeout(() => {
          setSuccess('');
        }, 3000);
      } catch (err) {
        setError(err.message);
        setTimeout(() => {
          setError('');
        }, 3000);
        setLoading(false);
      }
    };

    const modProps = () => {
      return {
        title: 'Remove User from Safe?',
        content: (
          <div>
            Are you sure you want to remove the safe from this user?
            <br />
            <br />
            {needToChooseNew && (
              <div>
                Designate new safe Owner:
                <br />
                <Select
                  style={{ width: 300 }}
                  placeholder="Select User"
                  value={val}
                  defaultValue={val}
                  onChange={v => {
                    val = v;
                    mod.update(modProps());
                  }}
                >
                  {vusers.map(u => (
                    <Select.Option
                      key={u.userId}
                      value={u.userId}
                    >{`${u.firstName} ${u.lastName}`}</Select.Option>
                  ))}
                </Select>
                <br />
                <br />
              </div>
            )}
            This user will no longer have access to this safe from the MyCube app.
          </div>
        ),
        canceText: 'Cancel',
        onCancel: () => 0,
        onOk: () => update(),
        okText: 'Remove',
        okButtonProps: {
          disabled: needToChooseNew && !val,
        },
      };
    };

    const mod = Modal.confirm(modProps());
  };

  useEffect(() => {
    setLoading(true);
    if (user) {
      retrieveData(user);
    }
  }, []);

  if (loading) {
    return (
      <div css={css(styles.loading)}>
        <AntdIcon type="loading" style={{ color: colors.highlight, fontSize: 40 }} />
      </div>
    );
  }

  return (
    <div>
      {error && <Alert css={css(SharedStyles.formAlert)} type="error" message={error} closable />}

      {success && (
        <Alert
          css={css(SharedStyles.formAlert)}
          type="success"
          message={success}
          closable
          onClose={() => setSuccess(null)}
        />
      )}
      <Card
        extra={
          <div css={css(SharedStyles.row, 'margin-bottom: 5px; float: right;')}>
            {!user.isDeleted && (
              <Button
                css={css`
                  margin-right: 20px;
                `}
                title="Delete User"
                warning
                onClick={onDeleteUser}
              />
            )}
            <span
              css={css`
                font-size: 16px;
                margin-right: 5px;
              `}
            >
              {active ? 'Active' : 'Not Active'}
              {loading && <Icon css={css('margin-left: 4px;')} type="loading" />}
            </span>
            <Switch
              onChange={loading || user.isDeleted ? () => 0 : toggleActive}
              checked={user.status}
            />
          </div>
        }
      >
        <List
          itemLayout="horizontal"
          dataSource={[
            { key: 'First Name', value: user.firstName },
            { key: 'Last Name', value: user.lastName },
            { key: 'Email', value: user.email },
            { key: 'Phone Number', value: user.phoneNumber || '' },
            { key: 'Is Deleted', value: user.isDeleted ? 'True' : 'False' },
          ]}
          renderItem={item => (
            <List.Item actions={[item.action]}>
              <List.Item.Meta
                title={
                  <p css={css(styles.title)}>
                    {item.key}: {item.value}
                  </p>
                }
              />
            </List.Item>
          )}
        />
      </Card>
      <div>
        <TableListHeader>
          <div css={css(SharedStyles.row, 'width: 40%; margin-top: 30px')}>
            <p
              css={css`
                font-size: 16px;
                font-weight: bold;
              `}
            >
              Safes
            </p>
          </div>
        </TableListHeader>
        <TableList
          canSelectRows={false}
          columns={columns}
          data={(user._safes || []).map(u => ({ ...u, key: u.id }))}
        />
      </div>
    </div>
  );
};

export const ManageUser = withRouter(ManageUserComponent);
