import { connect } from 'react-redux';
import { logout } from '../authentication/authentication.actions';
import { Dashboard as DashboardComponent } from './dashboard.component';

const mapStateToProps = state => {
  return { ...state };
};

const mapDispatchToProps = {
  logout,
};

export const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
