import colors from './colors';

export const GlobalInserts = `
  html, body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  #root {
    height: 100%;
  }
  
  .App {
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${colors.lightBackground}
  }
  .App .ant-input {
    border: 2px solid ${colors.inputBorder};
    border-radius: 0px;
    height: 40px;
    color: ${colors.highlight};
    font-size: 16px;
  }
  .App .ant-form-item-label {
    padding-bottom: 0px;
  }

  .App .ant-checkbox-wrapper {
    font-size: 14px;
    font-weight: bold;
  }

  .App .ant-btn {
    height: 38px;
  }

  .App .ant-card-head-title {
    color: ${colors.highlight}; 
  }

  .App .ant-list-item-meta-title {
    color: ${colors.highlight}; 
  }

  .App .ant-table tr {
    cursor: pointer;
  }

  .App .ant-table.ant-form-item {
    margin-bottom: 5px;
  }
  .App .ant-form-item-label label {
    font-size: 14px;
    font-weight: bold;
    color: ${colors.highlight};
  }

  .App .ant-menu {
    background-color: transparent;
  }
  .App .ant-menu-item {
    font-weight: bold;
  }
  .App .ant-menu-item-selected {
    color: ${colors.white};
  }

  .App .ant-table td {
    color: ${colors.highlight}; 
  }
  .App .ant-table-column-title {
    color: ${colors.highlight}; 
  }

  .App .ant-table-thead {
    background-color: ${colors.white};
  }

  .App .ant-table-small > .ant-table-content {
    background-color: ${colors.white};
  }

  
  .Link {
    color: ${colors.highlight};
    font-size: 12px;
    cursor: pointer;
  }
  `;

export default {
  logoWrap: `
    margin-bottom: 30px;
  `,
  row: `
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
};
