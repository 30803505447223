export const DASH_ACTIONS = {
  TOGGLE_DASH_LOADING: 'TOGGLE_DASH_LOADING',
  RETRIEVE_DATA: 'RETRIEVE_DATA',
  RETRIEVE_DATA_ERROR: 'RETRIEVE_DATA_ERROR',
  RETRIEVE_USERS: 'RETRIEVE_USERS',
  RETRIEVE_USERS_ERROR: 'RETRIEVE_USERS_ERROR',
  RETRIEVE_SAFES: 'RETRIEVE_SAFES',
  RETRIEVE_SAFES_ERROR: 'RETRIEVE_SAFES_ERROR',
  RETRIEVE_PAIRING_HISTORIES: 'RETRIEVE_PAIRING_HISTORIES',
  RETRIEVE_PAIRING_HISTORIES_ERROR: 'RETRIEVE_PAIRING_HISTORIES_ERROR',
};

const initialState = {
  users: null,
  deletedUsers: null,
  safes: null,
  pairingHistories: null,
  loading: false,
  error: null,
};

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASH_ACTIONS.TOGGLE_DASH_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case DASH_ACTIONS.RETRIEVE_DATA:
      return {
        ...state,
        ...action.payload,
        users: action.payload.users,
        safes: action.payload.safes,
        pairingHistories: action.payload.pairingHistories,
        error: null,
      };
    case DASH_ACTIONS.RETRIEVE_DATA_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case DASH_ACTIONS.RETRIEVE_USERS:
      return {
        ...state,
        users: action.payload.users,
        error: null,
      };
    case DASH_ACTIONS.RETRIEVE_USERS_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case DASH_ACTIONS.RETRIEVE_SAFES:
      return {
        ...state,
        safes: action.payload.safes,
        error: null,
      };
    case DASH_ACTIONS.RETRIEVE_SAFES_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case DASH_ACTIONS.RETRIEVE_PAIRING_HISTORIES:
      return {
        ...state,
        pairingHistories: action.payload.pairingHistories,
        error: null,
      };
    case DASH_ACTIONS.RETRIEVE_PAIRING_HISTORIES_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return {
        ...state,
      };
  }
};
