/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core';
import { getMobileOperatingSystem } from '../utils';
import styles from './styles';
import { Logo } from '../_shared/components';
import SharedStyles, { GlobalInserts } from '../_shared/styles';
import { Global } from '@emotion/core';
import { Button } from '../_shared/components';

const AppleImage = require('../_shared/assets/apple-store-badge.svg');
const AndroidImage = require('../_shared/assets/google-play-badge.svg');

export default class MobileSplash extends Component {
  state = { os: null };

  copyToClipboard = text => {
    try {
      var copyText = document.createElement('textarea');
      document.body.appendChild(copyText);
      copyText.style =
        'display: inline; width: 1px; position: fixed; left: -999999px; top: -999999px;';
      copyText.value = text;
      copyText.focus();
      document.execCommand('SelectAll');
      document.execCommand('Copy');
      document.body.removeChild(copyText);
    } catch (err) {
      console.error(`Failed to copy text: ${err.message}`);
    }
  };

  getAppUrl = () => {
    const { origin, href } = window.location;
    return href.replace(origin, 'mycube:/');
  };

  launchApp = () => {
    const url = this.getAppUrl();
    this.copyToClipboard(url);
    window.location.replace(url);
    this.timer = setTimeout(() => {
      alert('Please download the app by clicking the store button.');
    }, 1000);
  };

  goToStore = () => {
    const { os } = this.state;
    let url;
    if (os === 'Android') {
      url = 'https://play.google.com/store/apps/details?id=com.mycube';
    } else if (os === 'iOS') {
      url = 'https://apps.apple.com/us/app/mycube-safe/id1501787911';
    }

    const appCopy = this.getAppUrl();
    this.copyToClipboard(appCopy);

    if (url) {
      window.location.replace(url);
    }
  };

  componentDidMount = () => {
    const { href } = window.location;
    const contains = 'signup?request=';
    if (href.indexOf(contains) > -1) {
      this.launchApp();
    }
    const os = getMobileOperatingSystem();
    this.setState({ os });
  };

  render() {
    const { os } = this.state;
    const image = os === 'iOS' ? AppleImage : AndroidImage;

    return (
      <div className="App" css={css(styles.container)}>
        <Global styles={GlobalInserts} />
        <div css={css(SharedStyles.logoWrap)}>
          <Logo />
          <p css={css(styles.title)}>{'MyCube Safe'}</p>
          <div></div>
        </div>

        <Button onClick={this.launchApp} title="Open the MyCube Safe app" />

        <div onClick={this.goToStore} css={css(styles.brandDiv)}>
          <p css={css(styles.title, 'margin: 5px 0;')}>{"Don't have the app?"}</p>
          <div></div>
          <img css={css(styles.brandImage)} src={image} />
        </div>
      </div>
    );
  }
}
