/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Layout, Menu } from 'antd';
import { withRouter } from 'react-router';
import styles from './side-menu.styles';

export const SideMenuComponent = props => {
  const { routes, changeRoute, currentRoute } = props;

  const selectedKeys = routes
    .map((r, i) => {
      return (
        currentRoute &&
        currentRoute.highlighted &&
        currentRoute.highlighted === r.path &&
        i.toString()
      );
    })
    .filter(r => !!r);

  return (
    <Layout.Sider width={200} css={css(styles.container)}>
      <Menu mode="inline" selectedKeys={selectedKeys} style={{ height: '100%', borderRight: 0 }}>
        {routes.map((route, i) => {
          return (
            <Menu.Item
              key={i}
              onClick={() => {
                route.onClick ? route.onClick() : changeRoute(route);
              }}
            >
              {route.title}
            </Menu.Item>
          );
        })}
      </Menu>
    </Layout.Sider>
  );
};

export const SideMenu = withRouter(SideMenuComponent);
