/** @jsx jsx */

import { useState, useEffect } from 'react';
import { css, jsx } from '@emotion/core';
import { withRouter } from 'react-router-dom';
import { Form, Input, Alert } from 'antd';
import { get } from 'lodash';
import styles from '../authentication.styles';
import sharedStyles from '../../_shared/styles';
import Content from './forgot-password.content';
import { getContent, testEmail, buildErrorMsgFromForm } from '../../utils';
import { Button } from '../../_shared/components';
import { Logo } from '../../_shared/components';

export const ForgotPasswordComponent = props => {
  const { forgotPassword, form, history } = props;
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { resetPassword, email } = get(history, 'location.state');
  useEffect(() => {
    if (email) {
      form.setFieldsValue({ email });
    }
  }, []);

  const goToLogin = () => {
    history.push('/login');
  };
  const submit = async e => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    form.validateFieldsAndScroll(async (err, values) => {
      const { email } = values;
      if (err) {
        const error = buildErrorMsgFromForm(err);
        setError(error);
        setLoading(false);
        return;
      }
      try {
        const resp = await forgotPassword(email);
        if (resp && resp.CodeDeliveryDetails) {
          history.push('/forgotPasswordSubmit', { email });
        }
      } catch (err) {
        setLoading(false);
        if (err.code && err.code === 'UserNotFoundException') {
          setError('That email does not exist. Please try again.');
          return;
        }
        setError(err.message);
      }
    });
  };
  const _email = form.getFieldValue('email');
  const btnDisabled = !_email || !testEmail(_email);
  const content = getContent(Content);

  return (
    <div className="ForgotPassword" css={css(styles.container)}>
      <div css={css(sharedStyles.logoWrap)}>
        <Logo />
      </div>

      <p css={css(styles.title)}>{resetPassword ? content.resetPassword : content.title}</p>
      <p css={css(styles.subTitle)}>{content.subTitle}</p>

      {!!error && (
        <Alert
          message={error}
          css={css(styles.alert)}
          type={'error'}
          closable
          onClose={() => setError(null)}
        />
      )}

      <div css={css(styles.inputContainer)}>
        <Form layout="vertical" onSubmit={submit}>
          <Form.Item label={content.emailLabel}>
            {form.getFieldDecorator('email', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  type: 'email',
                  message: 'Enter a valid email address',
                },
                {
                  required: true,
                  message: 'Email is required',
                },
                {
                  max: 60,
                  message: 'Email is limited to 60 characters. ',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item>
            <div css={css(styles.buttonContainer + 'margin-top: 20px;')}>
              <Button
                loading={loading ? 1 : 0}
                title={content.button}
                onClick={submit}
                disabled={btnDisabled}
                width={300}
              />
              <div
                className="Link"
                css={css`
                  margin-top: 40px;
                  font-size: 14px;
                `}
                onClick={goToLogin}
              >
                {content.link}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export const ForgotPassword = Form.create()(withRouter(ForgotPasswordComponent));
