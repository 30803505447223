import React, { useState } from 'react';
import { message } from 'antd';
import { getSafes, getUsers } from '../../_shared/services';
import { Button } from '../../_shared/components';
import { keyBy, get, last } from 'lodash';
import { exportToCsv, sortByKey } from '../../utils';

export const ExportData = () => {
  const [loading, setLoading] = useState();

  const startExport = async () => {
    try {
      if (loading) {
        return;
      }

      setLoading(true);

      const [safes, users] = await Promise.all([getSafes(), getUsers()]);

      const userMap = keyBy(users, 'userId');

      // Safe Serial
      // Safe Device ID
      // Date Paired (safe createdAt, date only)
      // Owner (first name and last name of user who is set as Admin for the safe)
      // Email (of owner user)
      // Phone (of owner user, if available)
      // Other Users (comma-separate list of other users associated with the safe - just first name last name.  Should only include users with no endDate)

      const rows = [
        ['Safe Serial', 'Safe Device ID', 'Date Paired', 'Owner', 'Email', 'Phone', 'Other Users'],
      ];

      const getSafeOwner = safe => {
        const owners = get(safe, 'users', [])
          .filter(user => user.isAdmin && !user.endDate)
          .sort((a, b) => {
            var dateA = new Date(a.sDate);
            var dateB = new Date(b.sDate);
            return dateA - dateB;
          });
        const owner = last(owners);
        const u = get(userMap, get(owner, 'userId'));
        return u;
      };

      const getSafeUsers = safe => {
        const users = get(safe, 'users', []).filter(u => !u.endDate);
        return users.map(u => `${get(u, 'firstName', '')} ${get(u, 'lastName', '')}`).join(', ');
      };

      safes
        .filter(safe => safe.datePaired)
        .sort((a, b) => sortByKey(a, b, 'serial'))
        .map(safe => {
          const owner = getSafeOwner(safe);
          const row = [
            get(safe, 'serial', ''),
            get(safe, 'sk', ''),
            get(safe, 'datePaired', ''),
            `${get(owner, 'firstName', '')} ${get(owner, 'lastName', '')}`,
            get(owner, 'email', ''),
            get(owner, 'phoneNumber', ''),
            getSafeUsers(safe),
          ];

          rows.push(row);
        });

      exportToCsv(`DataExport-${new Date().toISOString()}.csv`, rows);
    } catch (err) {
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Button title="Export Data" loading={loading} onClick={startExport} />
    </div>
  );
};
