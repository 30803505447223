/** @jsx jsx */
import { useState } from 'react';
import { jsx, css } from '@emotion/core';
import Content from './setUpAccount.content';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { Form, Input, Alert } from 'antd';
import styles from '../authentication.styles';
import SharedStyles from '../../_shared/styles';
import { getContent, testPassword, PWReqsMessage, buildErrorMsgFromForm } from '../../utils';
import { Button, Logo } from '../../_shared/components';
import { createNewUser, completeNewPassword } from '../../_shared/services/authentication.service';
import { _User } from '../../utils/models';

const SetUpAccountComponent = props => {
  const { signIn, form, history, auth } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const goToLogin = () => {
    history.push('/login');
  };
  const submit = async e => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        const error = buildErrorMsgFromForm(err);
        setError(error);
        setLoading(false);
      }
      try {
        const { firstName, lastName, pw1, pw2, phoneNumber } = values;
        const { email } = get(history, 'location.state', '');

        if (!testPassword(pw1)) {
          setLoading(false);
          return setError(PWReqsMessage);
        }

        if (pw1 !== pw2) {
          setLoading(false);
          return setError('Passwords entered must match');
        }

        await completeNewPassword(auth.user, pw1);
        await createNewUser({
          user: new _User({
            email,
            firstName,
            lastName,
            phoneNumber,
          }),
        });
        await signIn(email, pw1);
        history.push('/dashboard');
      } catch (err) {
        setLoading(false);
        setError(err.message);
      }
    });
  };
  const { pw1, pw2, lastName, firstName } = form.getFieldsValue([
    'pw1',
    'pw2',
    'lastName',
    'firstName',
  ]);
  const btnDisabled = !(firstName && lastName && pw1 && pw1 === pw2);
  const content = getContent(Content);

  return (
    <div className="ForgotPasswordSubmit" css={css(styles.container)}>
      <div css={css(SharedStyles.logoWrap)}>
        <Logo />
      </div>

      <p css={css(styles.title)}>{content.title}</p>
      <p css={css(styles.subTitle)}>{content.subTitle}</p>
      {!!error && (
        <Alert
          message={error}
          css={css(styles.alert)}
          type={'error'}
          closable
          onClose={() => setError(null)}
        />
      )}

      <div css={css(styles.inputContainer)}>
        <Form layout="vertical" onSubmit={submit}>
          <Form.Item label={content.firstNameLabel}>
            {form.getFieldDecorator('firstName', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  message: 'First name required',
                },
                {
                  max: 50,
                  message: 'Last name is limited to 50 characters',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={content.lastNameLabel}>
            {form.getFieldDecorator('lastName', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  message: 'Last name required',
                },
                {
                  max: 50,
                  message: 'Last name is limited to 50 characters',
                },
              ],
            })(<Input />)}
          </Form.Item>
          <Form.Item label={content.phoneLabel}>
            {form.getFieldDecorator('phoneNumber')(<Input />)}
          </Form.Item>
          <Form.Item label={content.passwordLabel}>
            {form.getFieldDecorator('pw1', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  message: 'New Password is required',
                },
                {
                  max: 50,
                  message: 'Password limited to 50 characters',
                },
              ],
            })(<Input.Password />)}
          </Form.Item>
          <Form.Item label={content.confirmPasswordLabel}>
            {form.getFieldDecorator('pw2', {
              validateTrigger: 'onBlur',
              rules: [
                {
                  required: true,
                  message: 'Confirm Password is required',
                },
                {
                  validator: () => pw1 === pw2,
                  message: 'Passwords entered must match',
                },
                {
                  max: 50,
                  message: 'Password limited to 50 characters',
                },
              ],
            })(<Input.Password />)}
          </Form.Item>

          <Form.Item>
            <div css={css(styles.buttonContainer + 'margin-top: 20px;')}>
              <Button
                loading={loading ? 1 : 0}
                title={content.button}
                onClick={submit}
                disabled={btnDisabled}
                css={css`
                  width: 300px;
                `}
              />
              <div
                className="Link"
                css={css`
                  margin-top: 40px;
                `}
                onClick={goToLogin}
              >
                {content.link}
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export const SetUpAccount = Form.create()(withRouter(SetUpAccountComponent));
