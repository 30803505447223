import { Auth } from 'aws-amplify';
import { get, set } from 'lodash';
import { instance } from './instance';

export const createNewUser = async data => {
  const resp = await instance.post('/auth/signUp', {
    ...data,
  });
  return get(resp, 'data.user', null);
};

export const getUser = async userId => {
  const resp = await instance.get(`/auth/user/${userId}`);
  return get(resp, 'data.user', null);
};

export const updateUser = async data => {
  set(data, 'user.updatedAt', new Date().toISOString());
  const resp = await instance.post('/auth/updateUser', {
    ...data,
  });
  return get(resp, 'data.user', null);
};

export const deleteUser = async user => {
  const response = await instance.post('/auth/deleteUser', {
    ...user,
  });
  return response;
};

export const signUp = async (email, password) => {
  try {
    const resp = await Auth.signUp({
      username: email,
      password,
      attributes: {
        email: email,
      },
    });
    return resp.userSub;
  } catch (err) {
    if (err.code === 'UsernameExistsException') {
      throw new Error('Email address already exists');
    }
    if (err.code === 'InvalidPasswordException') {
      throw new Error(err.message);
    }
    throw new Error(err.code);
  }
};

export const signIn = async (email, password) => {
  try {
    const success = await Auth.signIn(email, password);
    return success;
  } catch (err) {
    const invalidCredentials =
      err.code === 'UserNotFoundException' || err.code === 'NotAuthorizedException';
    if (invalidCredentials) {
      throw new Error('That email and password combination is not valid');
    }
    throw err;
  }
};

export const completeNewPassword = async (user, password) => {
  return await Auth.completeNewPassword(user, password);
};

export const confirmSignUp = async (email, code) => {
  return await Auth.confirmSignUp(email, code);
};

export const changePassword = async (oldPassword, newPassword) => {
  const user = await Auth.currentAuthenticatedUser();
  return await Auth.changePassword(user, oldPassword, newPassword);
};

export const forgotPassword = async username => {
  return await Auth.forgotPassword(username);
};

export const forgotPasswordSubmit = async (username, code, password) => {
  return await Auth.forgotPasswordSubmit(username, code, password);
};

export const getUsers = async () => {
  const resp = await instance.get('/auth/allusers');
  return get(resp, 'data.data', null);
};

export const getDeletedUsers = async () => {
  const resp = await instance.get('/auth/deletedusers');
  return get(resp, 'data.data', null);
};

export const getToken = async data => {
  const resp = await instance.post('/auth/token', data);
  return get(resp, 'data.token');
};
