/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Content from './footer.content';
import { getContent } from '../utils';
import colors from '../_shared/colors';
import styled from '@emotion/styled';

const P = styled.p`
  color: white;
  font-size: 14px;
  line-height: 12px;
  margin: 20px;
`;

export const Footer = () => {
  const content = getContent(Content);
  return (
    <div
      css={css`
        background-color: ${colors.highlight};
        width: 100%;
        height: 50px;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        display: flex;
      `}
      className={'Footer'}
    >
      <P>{content.leftText}</P>
    </div>
  );
};
