import { get } from 'lodash';
import {
  signUp as signUpApi,
  confirmSignUp,
  signIn as signInApi,
  forgotPassword as forgotPasswordApi,
  forgotPasswordSubmit as forgotPasswordSubmitApi,
  getUser,
  getToken,
} from '../_shared/services';
import { AuthActions } from './authentication.reducer';

export const signUp = (username, password) => async dispatch => {
  const resp = await signUpApi(username, password);

  dispatch({ type: AuthActions.SET_CURRENT_USER, payload: resp.attributes });

  return resp ? true : false;
};

export const signIn = (username, password) => async dispatch => {
  try {
    const resp = await signInApi(username, password);
    if (resp && resp.challengeName && resp.challengeName === 'NEW_PASSWORD_REQUIRED') {
      dispatch({
        type: 'SET_CURRENT_USER',
        payload: resp,
      });
      return resp;
    }
    const cognitoGroup = get(resp, 'signInUserSession.idToken.payload.cognito:groups[0]');
    const isAdmin = cognitoGroup && cognitoGroup === 'admin';
    if (resp && !isAdmin) {
      dispatch(logout);
      return false;
    }

    const token = await getToken({ email: username });
    dispatch({ type: 'SET_TOKEN', token });
    dispatch(getUserOnSignIn(username, resp.attributes));

    return resp ? resp : false;
  } catch (error) {
    console.warn(error);
  }
};

export const verify = (email, code) => async () => {
  let resp = {};
  try {
    resp = await confirmSignUp(email, code);
  } catch (err) {
    resp = {
      error: err,
    };
  }
  return resp;
};

const getUserOnSignIn = (email, userAttributes) => async dispatch => {
  try {
    const user = await getUser(email);
    dispatch({
      type: 'SET_CURRENT_USER',
      payload: {
        ...userAttributes,
        ...user,
      },
    });
  } catch (error) {
    console.warn(error);
    throw error;
  }
};

export const forgotPassword = email => async () => {
  try {
    return await forgotPasswordApi(email);
  } catch (err) {
    console.warn(err);
    throw err;
  }
};

export const forgotPasswordSubmit = (email, code, password) => async () => {
  try {
    return await forgotPasswordSubmitApi(email, code, password);
  } catch (error) {
    console.warn(error);
    throw error;
  }
};

export const logout = () => async () => {
  localStorage.clear();
  window.location.reload();
};
