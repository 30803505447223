/** @jsx jsx */
import { jsx, css } from '@emotion/core';

export const Icon = props => {
  const { name, color, size } = props;
  return (
    <i
      className={`ICON fa fa-${name}`}
      css={css`
        color: ${color};
        fontsize: ${size}px;
      `}
    />
  );
};
