module.exports = {
  highlight: '#00500A',
  labelColor: '#4d4d4d',
  inputBorder: '#efefef',
  disabledColor: 'rgba(0,0,0,0.15)',
  error: '#F9423A',
  lightBackground: '#E7F5E0',
  white: 'white',
  lightGray: '#cfcfcf',
  transparent: 'transparent',
};
