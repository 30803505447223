/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { Table, Select } from 'antd';
import SharedStyles from '../styles';

export const TableList = props => {
  const {
    columns,
    data,
    canSelectRows,
    loading,
    implementScroll,
    showPagination,
    scrollHeight,
  } = props;

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [height, setHeight] = useState(500);
  const [pageSize, setPageSize] = useState(props.pageSize || 25);

  const calcTableSize = () => {
    const container = document.getElementById('ContentContainer');

    if (container) {
      const cHeight = container.offsetHeight;
      const subtract = 220;
      const height = cHeight - subtract;
      setHeight(height);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      calcTableSize();
    }, 500);
    window.addEventListener('resize', calcTableSize);
    return () => {
      window.removeEventListener('resize', calcTableSize);
    };
  }, []);

  const selectedRowsChange = () => {
    const { onSelectedRowsChange } = props;
    if (onSelectedRowsChange) {
      onSelectedRowsChange(selectedRowKeys);
    }
  };
  const rowSelection = () => {
    return {
      selectedRowKeys,
      onChange: keys => {
        setSelectedRowKeys(keys);
        selectedRowsChange();
      },
    };
  };
  // tslint:disable-next-line:no-any
  const rowClick = (record, rowIndex) => {
    const { rowClick } = props;
    if (rowClick) {
      rowClick(record, rowIndex);
    }
  };
  const changeSelect = v => {
    setPageSize(v);
  };
  const renderPageSelect = () => {
    const pageOptions = [
      { label: '25', value: 25 },
      { label: '50', value: 50 },
      { label: '100', value: 100 },
    ];

    const pagination = document.getElementsByClassName('ant-pagination')[0];
    let width = pagination ? 0 : 100;
    if (pagination) {
      width = pagination.offsetWidth + 20;
    }

    return (
      <div
        css={css(
          SharedStyles.row,
          `float: right;
          right: ${width}px;
        `
        )}
      >
        <label css={css('margin-right: 10px;')}>Page Size</label>
        <Select size="small" onChange={changeSelect} value={pageSize}>
          {pageOptions.map(option => (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  };
  useEffect(() => {
    changeSelect(pageSize);
  }, [pageSize, props.data]);

  return (
    <div>
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: () => rowClick(record, rowIndex),
          };
        }}
        pagination={showPagination && { pageSize }}
        size={'small'}
        scroll={implementScroll ? { y: scrollHeight || height } : undefined}
        rowSelection={canSelectRows ? rowSelection() : undefined}
        columns={columns}
        dataSource={data}
        loading={loading}
        rowClassName={(item, index) => `tbl-list-row-${index % 2} ${rowClick ? 'canClick' : ''}`}
      />
      {data.length > 25 && renderPageSelect()}
    </div>
  );
};

TableList.defaultProps = {
  canSelectRows: true,
  implementScroll: true,
  showPagination: true,
  pageSize: 25,
};
