import { connect } from 'react-redux';
import { MyAccount as MyAccountComponent } from './my-account.component';

const mapStateToProps = state => {
  const { user, loading } = state.auth;
  return {
    user,
    loading,
  };
};

export const MyAccount = connect(mapStateToProps)(MyAccountComponent);
