import { get } from 'lodash';

export const getEnv = () => {
  // return 'prod';
  const env = process.env.REACT_APP_ENV || process.env.NODE_ENV;
  if (env === 'development') {
    return 'local';
  } else if (env === 'production') {
    return 'prod';
  } else {
    return 'qa';
  }
};

export const getUrl = () => {
  const env = getEnv();
  const base = {
    local: 'http://localhost:3001',
    qa: 'https://qa.mycube-api.link',
    prod: 'https://prod.mycube-api.link',
  };
  const baseURL = get(base, env, base.qa);
  return `${baseURL}/api`;
};

// const URLS = {
//   auth: 'https://c0duem9sf4.execute-api.us-east-1.amazonaws.com/',
//   email: 'https://9o1az551kc.execute-api.us-east-1.amazonaws.com/',
//   manageSafes: 'https://ag5av8cn78.execute-api.us-east-1.amazonaws.com/',
//   manageInventory: 'https://6i1htc0ceh.execute-api.us-east-1.amazonaws.com/',
//   manageAccounts: 'https://v6iwthoqmi.execute-api.us-east-1.amazonaws.com/',
//   managePairingHistory: 'https://wml5os59fg.execute-api.us-east-1.amazonaws.com/',
//   publish: 'https://8q53uxq38b.execute-api.us-east-1.amazonaws.com/',
//   status: 'https://vu71jp8xcl.execute-api.us-east-1.amazonaws.com/',
//   deviceHistory: 'https://dr6412w1ui.execute-api.us-east-1.amazonaws.com/',
//   manageSerials: 'https://bl28fqa0ua.execute-api.us-east-1.amazonaws.com/',
// };

const API_KEYS = {
  manageSafes: '9YuwlrO2vp8Gk9lmDudf44xGvC1jivU69ZWOhXjb',
  manageAccounts: 'k5uXiDrf5t63Q0Fwi8k1ua7rlLSoYScu455k0eKh',
  managePairingHistory: 'EXzfLebvPY6ejzEZHdweS6aN6L3eS6s26S5fNB4F',
  manageInventory: '7Kpoux2SSiaWtKjcTF9Wc8lCF66UFMfa2SsPy1JR',
  manageSerials: 'UcgwKIPMXYaCFH4nLTIEg4JqrqdB7qEX7iPB8cIl',
};

const CONFIG = {
  local: {
    manageSafesApiKey: `${API_KEYS.manageSafes}`,
    manageInventoryApiKey: `${API_KEYS.manageInventory}`,
    manageAccountsApiKey: `${API_KEYS.manageAccounts}`,
    managePairingHistoryApiKey: `${API_KEYS.managePairingHistory}`,
    manageSerialsApiKey: `${API_KEYS.manageSerials}`,

    auth: getUrl(),
    email: getUrl(),
    manageSafes: getUrl(),
    manageInventory: getUrl(),
    manageAccounts: getUrl(),
    managePairingHistory: getUrl(),
    manageSerials: getUrl(),
    publish: getUrl(),
    status: getUrl(),
    deviceHistory: getUrl(),

    aws_user_pool: 'us-east-1_u4OTbjuRl',
    aws_region: 'us-east-1',
    aws_identity_pool: 'us-east-1:3919e2f8-8648-47d3-8a1e-7ab3c1e0db3c',
    aws_webclient_id: '4d9oatmatkihka419pf9hd5s7p',

    ToAddresses: ['admin@mycubesafe.com'],
    FromAddresses: ['no-reply@mycubesafe.com'],

    pusherChannelName: 'myCube',
    pusher: {
      appId: '972688',
      key: '4125e3e37fa6db3d658c',
      cluster: 'us3',
    },
    access_token: '97ccbc7a-b784-4c48-8df5-41d4d204969f',
    oneSignalAppId: 'a326d99e-d512-4d86-8063-01873b7a89c4',
    aws_access_key: 'AKIAVVVC5VFAOJ5F7LBW',
    aws_secret_key: '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',
  },
  qa: {
    manageSafesApiKey: `${API_KEYS.manageSafes}`,
    manageInventoryApiKey: `${API_KEYS.manageInventory}`,
    manageAccountsApiKey: `${API_KEYS.manageAccounts}`,
    managePairingHistoryApiKey: `${API_KEYS.managePairingHistory}`,
    manageSerialsApiKey: `${API_KEYS.manageSerials}`,

    auth: getUrl(),
    email: getUrl(),
    manageSafes: getUrl(),
    manageInventory: getUrl(),
    manageAccounts: getUrl(),
    managePairingHistory: getUrl(),
    manageSerials: getUrl(),
    publish: getUrl(),
    status: getUrl(),
    deviceHistory: getUrl(),

    aws_user_pool: 'us-east-1_u4OTbjuRl',
    aws_region: 'us-east-1',
    aws_identity_pool: 'us-east-1:3919e2f8-8648-47d3-8a1e-7ab3c1e0db3c',
    aws_webclient_id: '4d9oatmatkihka419pf9hd5s7p',

    ToAddresses: ['admin@mycubesafe.com'],
    FromAddresses: ['no-reply@mycubesafe.com'],

    pusherChannelName: 'myCube',
    pusher: {
      appId: '972688',
      key: '4125e3e37fa6db3d658c',
      cluster: 'us3',
    },
    access_token: '97ccbc7a-b784-4c48-8df5-41d4d204969f',
    oneSignalAppId: 'a326d99e-d512-4d86-8063-01873b7a89c4',
    aws_access_key: 'AKIAVVVC5VFAOJ5F7LBW',
    aws_secret_key: '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',
  },
  prod: {
    manageSafesApiKey: `${API_KEYS.manageSafes}`,
    manageInventoryApiKey: `${API_KEYS.manageInventory}`,
    manageAccountsApiKey: `${API_KEYS.manageAccounts}`,
    managePairingHistoryApiKey: `${API_KEYS.managePairingHistory}`,
    manageSerialsApiKey: `${API_KEYS.manageSerials}`,

    auth: getUrl(),
    email: getUrl(),
    manageSafes: getUrl(),
    manageInventory: getUrl(),
    manageAccounts: getUrl(),
    managePairingHistory: getUrl(),
    manageSerials: getUrl(),
    publish: getUrl(),
    status: getUrl(),
    deviceHistory: getUrl(),

    aws_user_pool: 'us-east-1_u4OTbjuRl',
    aws_region: 'us-east-1',
    aws_identity_pool: 'us-east-1:3919e2f8-8648-47d3-8a1e-7ab3c1e0db3c',
    aws_webclient_id: '4d9oatmatkihka419pf9hd5s7p',

    ToAddresses: ['admin@mycubesafe.com'],
    FromAddresses: ['no-reply@mycubesafe.com'],

    pusherChannelName: 'myCube',
    pusher: {
      appId: '972688',
      key: '4125e3e37fa6db3d658c',
      cluster: 'us3',
    },
    access_token: '97ccbc7a-b784-4c48-8df5-41d4d204969f',
    oneSignalAppId: 'a326d99e-d512-4d86-8063-01873b7a89c4',
    aws_access_key: 'AKIAVVVC5VFAOJ5F7LBW',
    aws_secret_key: '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',
  },
};

export default use => {
  use = use || getEnv();
  return CONFIG[use];
};
