/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Button as ButtonA } from 'antd';

const noop = () => 0;

export const Button = props => {
  const { onClick, title, disabled, warning } = props;

  const _onClick = e => {
    onClick && onClick(e);
  };
  return (
    <ButtonA
      className={'Button'}
      css={css`
        border-radius: 0;
      `}
      type={warning ? 'danger' : 'primary'}
      {...props}
      onClick={disabled ? noop : _onClick}
    >
      {title}
    </ButtonA>
  );
};
