/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import MCLogo from '../assets/logodarkgreen.png';

export const Logo = props => {
  const { small, style, onClick } = props;
  const mult = 0.621;
  const width = small ? 50 : 100;
  const height = width * mult;

  return (
    <div
      className={'Logo'}
      css={css`
        width: ${width}px;
        height: ${height}px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: ${onClick ? 'pointer' : 'default'};
      `}
      style={style}
      onClick={onClick}
    >
      <img
        alt={'MyCube'}
        css={css`
          width: 100%;
          height: 100%;
        `}
        src={MCLogo}
      />
    </div>
  );
};

Logo.defaultProps = {
  type: 'default',
};
