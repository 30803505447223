import React, { Component } from 'react';
import { configureStore } from './configureStore';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GlobalInserts } from './_shared/styles';
import { Global } from '@emotion/core';
import Navigation from './navigation';
import setup from './utils/setup';
import { getMobileOperatingSystem } from './utils';
import MobileSplash from './mobile-splash';

export const { store, persistor } = configureStore();
setup();

export default class App extends Component {
  state = { showMobileSplash: false };

  componentDidMount = () => {
    const os = getMobileOperatingSystem();
    this.setState({ showMobileSplash: os !== 'unknown' });
  };

  render() {
    const { showMobileSplash } = this.state;
    if (showMobileSplash) {
      return <MobileSplash />;
    }
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Global styles={GlobalInserts} />
          <Navigation />
        </PersistGate>
      </Provider>
    );
  }
}
