import { get } from 'lodash';
import { removeEmpty } from '../../utils';
import { instance } from './instance';

export const getSerial = async id => {
  try {
    const resp = await instance.get(`/serial/${id}`, { data: {} });
    return get(resp, 'data.serial', undefined);
  } catch (err) {
    return undefined;
  }
};

export const getSerials = async where => {
  let resp = {};
  if (where) {
    resp = await instance.post('/serials', {
      where,
    });
  } else {
    resp = await instance.get('/serials', { data: {} });
  }
  return get(resp, 'data.serials', []);
};

export const createSerial = async serial => {
  const resp = await instance.post('/serial/create', {
    serial: removeEmpty(serial),
  });
  return get(resp, 'data.data', null);
};

export const updateSerial = serial => {
  return createSerial(serial);
};

export const deleteSerial = async serial => {
  const resp = await instance.delete(`/serial/${serial.serial}`);
  return get(resp, 'data.serial', null);
};
